export const menus = [
	{
		"id": "1000",
		"name": "首页",
		"path": "/home",
	}
];
export const me_info = {
	"id": "2001",
	"title": "国广（上海）信息科技有限公司",
	"web": "www.gmgft.com",
	"address": "北京市广渠路42号院1号楼4层401",
	"telephone": "010-87745956",
	"fax": "010-87745956",
	"email": "service@gmgft.com",
	"icp": "沪ICP备19035647号-1",
	"summary_cn": "发现投资机会，揭示投资风险",
	"summary_en": "Discovering Investment Opportunities and Revealing Investment Risks",
	"descs": [
		"国广（上海）信息科技有限公司（简称：国广财经）致力于成为中国最具规模和权威的全球财经新闻及商业数据服务商。",
		"国广财经立足于国广环球传媒控股有限公司旗下雄厚的媒体和资讯实力，利用云计算、大数据、人工智能等先进技术，聚合“媒体、金融、科技”的力量，搭建金融信息服务平台，致力于为客户提供全面、优质、高效的金融服务，向参与全球证券、外汇与货币、固定收益、大宗商品等市场的机构和投资者，提供财经资讯、研究报告、行情数据、宏观及行业经济数据、企业数据等金融信息及分析工具，为研究、交易、投资及风险管理提供信息支持，为金融监管机构提供决策参考和政策发布渠道。"
	],
}