<template>
	<div class="header_container">
		<div class="header">
			<div class="header-box">
				<div class="logo-bg" @click.stop="handlePush">
					<img class="icon" src="@/assets/images/wj_icon_white.png" alt="万界数据" />
					<div class="logo-title-bg">
						<div class="logo-title">万界数据</div>
						<div class="logo-title-en">WANJIE DATA</div>
					</div>
				</div>
				<div  class="login-btn" href="" @click.stop="handleLogin">登录</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Nav',
	methods:{
		handleLogin(){

		}
	}
};
</script>

<style lang="scss" scoped>
.header_container {
	z-index: 999;
    position: fixed;
    width: 100%;
    top: 0;
    background-color: transparent;
	.header{
		height: 94px;
		padding-left: 80px;
		padding-right: 80px;
		display: flex;
		align-items: center;
		.header-box{
			flex: 1;
			display: flex;
			justify-content: space-between;
			.logo-bg{
				font-size: 0px;
    			cursor: pointer;
				display: flex;
				flex-direction: row;
				align-items: center;
				.icon{
					width: 42px;
					height: 42px;
				}
				.logo-title-bg{
					width: 100px;
					height: 100%;
					margin: 5px 0 0 16px;
					color: #fff;
					display: flex;
					flex-direction: column;
					justify-content: center;
					.logo-title{
						font-size: 22px;
						font-weight: 900;
						line-height: 22px;
					}
					.logo-title-en{
						font-size: 13px;
						line-height: 16px;
						font-weight: 500;
					}
				}
			}
			.login-btn{
				display: none;
				box-sizing: border-box;
				border: 1px solid #ffffff;
				cursor: pointer;
				color: #fff;
				padding: 0px 30px;
				height: 42px;
				line-height: 42px;
				border-radius: 4px;
				font-family: MiSans;
				font-size: 18px;
				font-weight: 500;
				background-color: #0d0012;
			}
		}
	}
}
</style>
