import Vue from 'vue'
import Search from './Search'
import Nav from './Nav.vue'
import Footer from './Footer.vue'
import { returnValue } from '@/utils/index'
function piugin () {
  Vue.component('Search', Search)
  Vue.component('Nav', Nav)
  Vue.component('Footer', Footer)
  Vue.prototype.returnValue = returnValue
}

export default piugin
