import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)


const routes = [
	{
		path: '/',
		redirect: '/home',
	}, {
		path: '/home',
		name: 'Home',
		component: () => import('@/views/Home.vue'),
		meta: {
			title: '首页'
		}
	}
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
  if (to.name === 'DetailV1') {
    window.scrollTo(0, 0); // 将页面滚动到顶部
  }
  next();
});

export default router

export function resetRouter() {
    router.push({ path: '/login' })
}
