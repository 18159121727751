<template>
	<div id="app">
		<Nav />
		<router-view/>
		<Footer />
	</div>
</template>

<script>
export default{
	name: 'App',
}
</script>

<style lang="scss">
#app{
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	background-color: rgba(9, 9, 45, 1);
}
body{
	min-width: 320px;
}
</style>
